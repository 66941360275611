import React from "react";
import { useTranslation } from "react-i18next";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";

import { LeaderboardData } from "../../app/types";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof LeaderboardData) => void;
  orderBy: string;
};

export const LeadeboardTableHead: React.FC<EnhancedTableProps> = (props) => {
  const { t } = useTranslation();
  const { orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof LeaderboardData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ "td, th": { border: 0 } }}>
        <TableCell />
        <TableCell align="right" padding="none" sortDirection="desc">
          <TableSortLabel
            active={orderBy === "numberOfWords"}
            direction="desc"
            onClick={createSortHandler("numberOfWords")}
          >
            {t("leaderboard.numberOfWords")}
          </TableSortLabel>
        </TableCell>
        <TableCell align="right" padding="none" sortDirection="desc">
          <TableSortLabel
            active={orderBy === "memoryStrength"}
            direction="desc"
            onClick={createSortHandler("memoryStrength")}
          >
            {t("leaderboard.memoryStrength")}
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { PageLayout } from "../../components/PageLayout/PageLayout";
import { LearningGoal } from "../../components/LearningGoal/LearningGoal";

export const AI: React.FC = () => {

  return (
    <PageLayout>

      <LearningGoal />

      <Box sx={{ width: "100%", my: 4 }}>
        <Divider />
      </Box>

    </PageLayout>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { StorageNames } from "../../app/types";
import { useUser } from "../../hooks/useUser";
import { SimpleError } from "../../components/SimpleError/SimpleError";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { ClaimedWords } from "../../components/ClaimedWords/ClaimedWords";
import { auth, isSignInWithEmailLink, verifyPasswordlessLink } from "../../app/firebase";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const Home: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isUserLoggedIn, isForeignLanguageSet } = useUser();

  const openLogin = async () => {
    navigate("/sign-in");
  };

  // Handle first time sign in
  useEffect(() => {
    const setUpUser = async () => {
      navigate("/adjust");
    };
    if (isUserLoggedIn && !isForeignLanguageSet) {
      setUpUser();
    }
  }, [isUserLoggedIn, isForeignLanguageSet]);

  // Handle passwordless sign in from link
  const [passwordlessEmailError, setPasswordlessEmailError] = useState<Error | null>(null);
  const [passwordlessEmailLoading, setPasswordlessEmailLoading] = useState<boolean>(false);
  useEffect(() => {
    const handlePasswordlessLink = async (passwordlessEmail: string) => {
      setPasswordlessEmailLoading(true);
      const result = await verifyPasswordlessLink(passwordlessEmail);
      if (result instanceof Error) {
        setPasswordlessEmailError(new Error(result.message));
      }
      setPasswordlessEmailLoading(false);
    };
    const passwordlessEmail = window.localStorage.getItem(StorageNames.PASSWORDLESS_EMAIL);
    if (isSignInWithEmailLink(auth, window.location.href) && passwordlessEmail) {
      window.localStorage.removeItem(StorageNames.PASSWORDLESS_EMAIL);
      handlePasswordlessLink(passwordlessEmail);
    }
  }, []);

  if (passwordlessEmailLoading) {
    return (
      <PageLayout>
        <CircularProgress />
      </PageLayout>
    );
  }

  if (passwordlessEmailError) {
    return (
      <PageLayout>
        <SimpleError error={passwordlessEmailError} />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {isUserLoggedIn ? <ClaimedWords /> : (
        <>
          <Typography variant="h6" gutterBottom>
            {t("home.welcome")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("home.signInToContinue")}
          </Typography>
          <Box sx={{ my: 3 }}>
            <Button onClick={openLogin} variant="outlined">
              {t("general.signIn")}
            </Button>
          </Box>
        </>
      )}
    </PageLayout>
  );
};

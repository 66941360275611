import { FC, useEffect, useRef } from "react";
import { KeyboardLayoutObject, KeyboardReact as Keyboard } from "react-simple-keyboard";
import defaultEnglishLayout from "simple-keyboard-layouts/build/layouts/english";

import Box from "@mui/material/Box";

import React from "react";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";

import { keyboardButtonTheme, keyboardLayouts } from "../../app/keyboardLayouts";
import { ForeignLanguages, VirtualKeyboardContextType } from "../../app/types";
import { VirtualKeyboardContext } from "../../components/KeyboardWrapper/KeyboardWrapper";

import "react-simple-keyboard/build/css/index.css";
import "./keyboard.styles.css";

type Props = {
  handleButtonRelease: (input: string) => void;
  foreignLanguage: ForeignLanguages;
};

export const VirtualKeyboard: FC<Props> = ({ handleButtonRelease, foreignLanguage }) => {
  const theme = useTheme();
  const { setKeyboardHeight } = React.useContext(
    VirtualKeyboardContext
  ) as VirtualKeyboardContextType;

  const keyboardBoxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!keyboardBoxRef.current) return;
    setKeyboardHeight(keyboardBoxRef.current.clientHeight);
  }, [setKeyboardHeight]);

  if (!theme || !foreignLanguage) {
    return null;
  }

  return (
    <Drawer variant="permanent" anchor="bottom" PaperProps={{ sx: { borderWidth: 0 } }}>
      <Box
        ref={keyboardBoxRef}
        component="div"
        sx={{ maxWidth: theme.breakpoints.values.md, width: "100%", margin: "0 auto" }}
      >
        <Keyboard
          physicalKeyboardHighlight={true}
          disableButtonHold={true}
          onKeyReleased={handleButtonRelease} // should be onKeyPressed if physicalKeyboardHighlightPress={true}, but due to a bug in react-simple-keyboard, we cannot use it and handle the physical keys ourselves in Blackboard component
          buttonTheme={keyboardButtonTheme}
          layoutName="default"
          layout={
            (keyboardLayouts[foreignLanguage] as KeyboardLayoutObject) || defaultEnglishLayout
          }
        />
      </Box>
    </Drawer>
  );
};

export default VirtualKeyboard;

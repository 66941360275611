import React from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

import CircularProgress from "@mui/material/CircularProgress";

import { useUser } from "../../hooks/useUser";
import { Blackboard } from "../../components/Blackboard/Blackboard";
import { SimpleError } from "../../components/SimpleError/SimpleError";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { useGetNextFlashcardQuery, useSaveFlashcardProgressMutation } from "../../redux/api";
import { KeyboardWrapper } from "../../components/KeyboardWrapper/KeyboardWrapper";
import { useParams } from "react-router-dom";
import { LessonType } from "../../app/types";
import { useTranslation } from "react-i18next";

const LessonContent: React.FC = () => {
  const { lessonType } = useParams()
  const lessonTypeError = React.useMemo(() => {
    if (lessonType !== LessonType.NEW && lessonType !== LessonType.REPEAT) {
      return new Error("Lesson type is not valid");
    }
    return null;
  }, [lessonType]);

  const { foreignLanguage, nativeLanguage } = useUser();

  const { t } = useTranslation();

  const [
    saveFlashcardProgress,
    { isLoading: isProgressLoading, isError: isProgressError, error: progressError },
  ] = useSaveFlashcardProgressMutation();

  const {
    data: flashcard,
    isLoading: isFlashcardLoading,
    isError: isFlashcardError,
    error: flashcardError,
  } = useGetNextFlashcardQuery(
    lessonTypeError
      ? skipToken
      : { foreignLanguage, nativeLanguage, lessonType: lessonType as LessonType }
  );

  const [numberOfMistakes, setNumberOfMistakes] = React.useState<number | null>(null);

  // Callbacks
  const saveAndMoveToNextFlashcard = React.useCallback(async () => {
    if (!flashcard) return;
    if (numberOfMistakes === null) return;
    if (lessonTypeError) return;
    await saveFlashcardProgress({
      wordId: flashcard.wordId,
      numberOfMistakes,
      nativeLanguage,
      foreignLanguage,
      lessonType: lessonType as LessonType
    });
    setNumberOfMistakes(null);
  }, [flashcard, foreignLanguage, nativeLanguage, numberOfMistakes, saveFlashcardProgress]);

  // Render
  if (isProgressLoading || isFlashcardLoading) {
    return (
      <PageLayout>
        <CircularProgress />
      </PageLayout>
    );
  }

  if (isProgressError || isFlashcardError || lessonTypeError) {
    return (
      <PageLayout>
        <SimpleError error={progressError || flashcardError || lessonTypeError || null} />
      </PageLayout>
    );
  }

  console.log(flashcard)

  return (
      <KeyboardWrapper>
      {!!flashcard ? (
        <Blackboard
          currentFlashcard={flashcard}
          numberOfMistakes={numberOfMistakes}
          setNumberOfMistakes={setNumberOfMistakes}
          saveAndMoveToNextFlashcard={saveAndMoveToNextFlashcard}
        />
      ) : (
        <SimpleError error={t("lesson.noFlashcards")} />
      )}
    </KeyboardWrapper>
  )
};

export const Lesson: React.FC = () => {
  return (
    <PageLayout contentWidth="lg">
      <LessonContent />
    </PageLayout>
  );
}


import { FC } from "react";

import { Box } from "@mui/material";
import { CharInfo, CharType } from "../../app/types";
import {
  wrapper,
  box,
  centered,
  charNew,
  charActive,
  charCorrect,
  charIncorrect,
  charIncorrectFixed,
  charExempted,
  boxMinWidth,
  boxFixedWidth,
  boxBorder,
} from "./letter.styles";

const typeStyles = {
  [CharType.NEW]: charNew,
  [CharType.CORRECT]: charCorrect,
  [CharType.INCORRECT]: charIncorrect,
  [CharType.INCORRECT_FIXED]: charIncorrectFixed,
  [CharType.EXEMPTED]: charExempted,
};

export const Letter: FC<CharInfo> = ({ letter, type, isActive, isHidden }) => {
  const lineBreak = letter === " ";
  return (
    <Box sx={{ ...(lineBreak ? {} : wrapper) }}>
      {lineBreak ? null : (
        <Box
          sx={{
            ...box,
            ...(type === CharType.EXEMPTED ? boxMinWidth : boxFixedWidth),
            ...(type === CharType.NEW || isActive ? boxBorder : {}),
            ...centered,
            ...(typeStyles[type] || {}),
            ...(isActive && charActive),
          }}
        >
          {isHidden ? "\u00A0" : letter}
        </Box>
      )}
    </Box>
  );
};

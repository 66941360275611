import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import RecommendIcon from "@mui/icons-material/Recommend";
import Typography from "@mui/material/Typography";
import { SuggestFeature } from "../SuggestFeature/SuggestFeature";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/useRedux";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useGetSuggestionsQuery, useUpvoteSuggestionMutation } from "../../redux/api";
import { CircularProgress } from "@mui/material";

export const Suggestions: React.FC = () => {
  const { t } = useTranslation();

  const { userToken } = useAppSelector((state) => state.user);
  const { data: suggestions, isLoading: isSuggestionsLoading } = useGetSuggestionsQuery(userToken ? undefined : skipToken);
  const [upvoteSuggestion, { isLoading: isLoadingUpvoteSuggestion }] = useUpvoteSuggestionMutation();

  const handleUpvote = async (suggestionId: number) => {
    if (isLoadingUpvoteSuggestion) return;
    await upvoteSuggestion({ suggestionId });
  };

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        {t("suggestions.suggestions")}
      </Typography>
      <SuggestFeature />
      {isSuggestionsLoading ? <CircularProgress /> :
        !suggestions?.length ? (
          <Typography variant="body1">{t("suggestions.noSuggestions")}</Typography>
        ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "100%", fontWeight: "bold" }}>
                  {t("suggestions.suggestions")}
                </TableCell>
                <TableCell />
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("suggestions.score")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suggestions.map((suggestion) => (
                <TableRow
                  key={suggestion.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  selected={suggestion.isOwner}
                >
                  <TableCell component="th" scope="row">
                    {suggestion.suggestion}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="recommend"
                      onClick={() => handleUpvote(suggestion.id)}
                      disabled={isLoadingUpvoteSuggestion || suggestion.hasVoted}
                    >
                      <RecommendIcon color={suggestion.hasVoted ? "primary" : "inherit"} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">{suggestion.score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        )}
    </>
  );
};

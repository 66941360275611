import { StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import App from "./app/App";
import { store } from "./redux/store";
import { FirebaseAuth } from "./components/FirebaseAuth/FirebaseAuth";

// Translations
import "./app/i18n";

const theme = createTheme();

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement!);

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <FirebaseAuth>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </FirebaseAuth>
    </ReduxProvider>
  </StrictMode>
);

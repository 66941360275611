export const translations = {
  en: {
    translation: {
      nav: {
        learn: "Learn",
        compete: "Compete",
        adjust: "Adjust",
        ai: "AI",
      },
      general: {
        thereWasAnError: "There was an error",
        loading: "Loading...",
        signIn: "Sign In",
        signOut: "Sign Out",
        continue: "Continue",
        save: "Save",
        name: "Name",
        email: "Email",
      },
      home: {
        welcome: "Welcome",
        signInToContinue: "Please sign in to continue",
      },
      leaderboard: {
        leaderboard: "Today's leaderboard",
        numberOfWords: "Number of words",
        memoryStrength: "Memory strength",
        setLeaderboardName: "Set your name for public leaderboard",
        noEntries: "There are no entries yet.",
      },
      signIn: {
        emailSent: "Please check your email. We have sent you a sign in code.",
        signInWithGoogle: "Continue with Google",
        or: "OR",
        signInWithEmail: "Email me a sign in code",
        disclaimer:
          "Please understand that this is a prototype and your data might get deleted in the process of improving the system.",
      },
      account: {
        languages: "Select languages",
        nativeLanguage: "Native language",
        foreignLanguage: "Foreign language",
        languagesDescription:
          "Please select your native language and the language that you want to learn.",
      },
      lesson: {
        translateWithManyMistakes: "Translate. Make as many mistakes as you want.",
        next: "Next",
        nextTooltip: "Or press Enter",
        reportCorrectionTitle: "Report a mistake",
        reportCorrectionDescription: "Please tell us what is wrong with this word.",
        reportCorrectionPlaceholder: "Write here...",
        noFlashcard: "No flashcard found",
      },
      suggestions: {
        suggestions: "Suggestions",
        score: "Upvotes",
        addSuggestion: "Suggest a feature",
        writeYourSuggestion: "Write your suggestion",
        submitSuggestion: "Submit suggestion",
        noSuggestions: "There are no suggestions yet.",
      },
      learningGoal: {
        title: "Learning goal",
        description: "Share your learning goal so that you get a custom selection of vocabulary.",
        writeYourGoal: "What is your learning goal?",
        submitGoal: "Save",
        yourGoalIs: "Your learning goal",
        changeGoal: "Change",
        goalCategories: "Goal categories",
      },
      claimedWords: {
        title: "My words",
        strength: "Strength",
        words: "Words",
        noWords: "You have no words yet.", 
        repeatWords: "Repeat words",
      },
    },
  },
  bg: {},
  sk: {},
  cs: {},
};

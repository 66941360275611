
import React from "react";
import { useTranslation } from "react-i18next";
import { projectSettings } from "../app/config";
import { ForeignLanguages, NativeLanguages, UserLanguages } from "../app/types";
import { useGetUserSettingsQuery } from "../redux/api";
import { useAppSelector } from "./useRedux";
import { userSlice } from "../redux/userSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export const useUser = (): UserLanguages => {
  const { i18n } = useTranslation();
  const { userToken, isUserLoading, isUserError } = useAppSelector((state) => state.user);
  const { data: userSettings, isLoading: isUserSettingsLoading, isError: isUserSettingsError, error: userSettingsError } = useGetUserSettingsQuery(
    !userToken || isUserLoading || isUserError ? skipToken : undefined
  );

  React.useEffect(() => {
    if (userSettingsError) {
      let error = new Error("User Settings error");
      if ("data" in userSettingsError) {
        error = new Error(`User Settings error: ${userSettingsError.data}`);
      } else if ("message" in userSettingsError) {
        error = new Error(`User Settings error: ${userSettingsError.message}`);
      }
      userSlice.actions.setError(error);
    }
  }, [userSettingsError]);

  // Update i18n language if userSettings.nativeLanguage changes
  React.useEffect(() => {
    if (
      userSettings?.nativeLanguage &&
      Object.keys(projectSettings.availableLanguages).includes(userSettings.nativeLanguage)
    ) {
      i18n.changeLanguage(userSettings.nativeLanguage);
    }
  }, [userSettings?.nativeLanguage, i18n]);

  const resolvedLanguage = i18n.resolvedLanguage && Object.values(NativeLanguages).includes(i18n.resolvedLanguage as NativeLanguages) ? i18n.resolvedLanguage as NativeLanguages : NativeLanguages.EN;

  return {
    nativeLanguage: userSettings?.nativeLanguage || resolvedLanguage,
    foreignLanguage: userSettings?.foreignLanguage || ForeignLanguages.EN,
    isForeignLanguageSet: !!userSettings?.foreignLanguage,
    isUserLoggedIn: !!userToken && !isUserSettingsLoading && !isUserSettingsError,
    isUserLoading,
    isUserSettingsLoading,
  };
};

import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AnnouncementIcon from "@mui/icons-material/Announcement";

import { useSaveCorrectionMutation } from "../../redux/api";
import { useUser } from "../../hooks/useUser";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};

type Props = {
  wordId: number;
};

export const CorrectionModal: React.FC<Props> = ({ wordId }) => {
  const { t } = useTranslation();
  const { nativeLanguage, foreignLanguage } = useUser();
  const [saveCorrection, { isLoading }] = useSaveCorrectionMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (foreignLanguage === null) {
      return;
    }
    const correctionText = event.currentTarget.correction.value;
    await saveCorrection({ correctionText, wordId, nativeLanguage, foreignLanguage });
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" color="info" sx={{ mr: 3 }} onClick={handleOpen}>
        <AnnouncementIcon sx={{ mr: 1 }} /> {t("lesson.reportCorrectionTitle")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("lesson.reportCorrectionTitle")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("lesson.reportCorrectionDescription")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              type="text"
              required
              fullWidth
              label={t("lesson.reportCorrectionPlaceholder")}
              name="correction"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ my: 2 }} disabled={isLoading}>
              {isLoading ? t("general.loading") : t("general.save")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

import { FC } from "react";
import { useTranslation } from "react-i18next";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { SerializedError } from "@reduxjs/toolkit/dist/createAsyncThunk";

type Props = {
  error: Error | FetchBaseQueryError | SerializedError | string | null;
};

export const SimpleError: FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  if (typeof error === "string") {
    return (
      <Alert severity="error">
        <AlertTitle>{t("general.thereWasAnError")}</AlertTitle>
        {error}
      </Alert>
    );
  }


  if ("status" in error) {
    // you can access all properties of `FetchBaseQueryError` here
    const errMsg = "error" in error ? error.error : JSON.stringify(error.data);

    return (
      <Alert severity="error">
        <AlertTitle>{t("general.thereWasAnError")}</AlertTitle>
        {errMsg}
      </Alert>
    );
  } else {
    // you can access the properties of `SerializedError` / `Error` here
    return (
      <Alert severity="error">
        <AlertTitle>{t("general.thereWasAnError")}</AlertTitle>
        {error.message}
      </Alert>
    );
  }
};

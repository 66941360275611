import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
  onIdTokenChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { publicConfig } from "./config";
import { NativeLanguages, StorageNames } from "./types";

export const firebaseConfig = {
  apiKey: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_API_KEY,
  authDomain: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_AUTH_DOMAIN,
  projectId: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_PROJECT_ID,
  storageBucket: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_MESSAGING_SENDER_ID,
  appId: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_APP_ID,
  measurementId: publicConfig.REACT_APP_NOT_SECRET_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    console.error(err);
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

const registerWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  signOut(auth);
};

export const sendPasswordlessEmail = (email: string, language: NativeLanguages) => {
  auth.languageCode = language;
  const actionCodeSettings = {
    url:
      process.env.NODE_ENV === "development"
        ? `${publicConfig.REACT_APP_NOT_SECRET_FRONTEND_LOCAL}`
        : `${publicConfig.REACT_APP_NOT_SECRET_FRONTEND_URL}`,
    handleCodeInApp: true, // This must be true as per the docs
  };
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem(StorageNames.PASSWORDLESS_EMAIL, email);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.warn(errorCode, errorMessage);
    });
};

export const verifyPasswordlessLink = (email: string) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    return signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        return result.user;
      })
      .catch((error) => {
        const errorMessage = error.message;
        return new Error(errorMessage);
      });
  }
};

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  onAuthStateChanged,
  onIdTokenChanged,
  isSignInWithEmailLink,
};

import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { useTranslation } from "react-i18next";
import { useSaveUserSettingsMutation } from "../../redux/api";

export const LeaderboardName = () => {
  const { t } = useTranslation();
  const [isContactFormVisible, setIsContactFormVisible] = React.useState(false);
  const [leaderboardName, setLeaderboardName] = React.useState("");
  const [saveUserSettings, { isLoading }] = useSaveUserSettingsMutation();

  const handleShowContactForm = () => {
    setIsContactFormVisible(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await saveUserSettings({ leaderboardName });
    setIsContactFormVisible(false);
  };

  return (
    <Box my={2}>
      {isContactFormVisible ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", mb: 1 }}
        >
          <TextField
            size="small"
            type="text"
            required
            label={t("general.name")}
            name="name"
            value={leaderboardName}
            onChange={(e) => setLeaderboardName(e.target.value)}
            sx={{ mr: 1 }}
          />
          <Button type="submit" variant="contained" disabled={isLoading}>
            {t("general.save")}
          </Button>
        </Box>
      ) : (
        <Button size="small" onClick={handleShowContactForm} variant="outlined">
            {t("leaderboard.setLeaderboardName")}
        </Button>
      )}
    </Box>
  );
};

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';

import { projectSettings } from '../../app/config';
import { Breakpoint, CircularProgress, Container } from '@mui/material';
import { appRoutes } from '../../app/App';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';

const drawerWidth = 240;

interface Props {
  children: React.ReactNode
  contentWidth?: Breakpoint
}


export const PageLayout: React.FC<Props> = ({ contentWidth, children }) => {
  const { t } = useTranslation();
  const { isUserLoggedIn, isUserLoading, isUserSettingsLoading } = useUser();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [bottomNavSelection, setBottomNavSelection] = React.useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {projectSettings.projectName}
      </Typography>
      <Divider />
      <List>
        {appRoutes.filter(route => !!route.nav).map((route) => (
          <ListItem key={route.id} disablePadding>
            <ListItemButton component={NavLink} to={route.nav || ''} sx={{ textAlign: 'center' }}>
              <ListItemText primary={t(`nav.${route.id}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar variant="dense">
          <Container maxWidth="xs" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
              <Typography variant="h6" component="div">
                {projectSettings.projectName}
              </Typography>
            </Link>
            {isUserLoggedIn && !isUserSettingsLoading && (
              <>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {appRoutes.filter(route => !!route.nav).map((route) => (
                    <Button component={NavLink} to={route.nav || ''} key={route.id} sx={{
                      color: blue[50],
                      '&:hover': {
                        backgroundColor: blue[800],
                      },
                      minWidth: 'auto',
                    }} >
                      {t(`nav.${route.id}`)}
                    </Button>
                  ))}
                </Box>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
          </Container>
        </Toolbar>
      </AppBar>
      {isUserLoggedIn && !isUserSettingsLoading && (
        <Box component="nav">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      )}
      <Box component="main" sx={{ px: 3, pt: 10, pb: 3, flexGrow: 1, justifyContent: 'center' }}>
        <Container maxWidth={contentWidth || 'xs'} sx={{ textAlign: 'center' }}>
          {isUserLoading || isUserSettingsLoading ? <CircularProgress /> : children}
        </Container>
      </Box>
      <Paper sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: { sm: 'none' }
      }}
        elevation={3}
      >
        {isUserLoggedIn && !isUserSettingsLoading && (
          <BottomNavigation
            showLabels
            value={bottomNavSelection}
            onChange={(_, newSelectedIndex) => {
              setBottomNavSelection(newSelectedIndex);
            }}
          >
            <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
            <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} />
          </BottomNavigation>
        )}
      </Paper>
    </>
  );
}

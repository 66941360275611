import { DateTime, Duration } from "luxon";
import { baseUrl, projectSettings, sharedSettingsBackendAndFrontend } from "./config";
import { keyboardLayouts } from "./keyboardLayouts";
import { ForeignLanguages } from "./types";

export const allDiacritics: string = Object.keys(projectSettings.diacriticChars).join("");
export const allSlavonic: string =
  "АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯЁЂЃЄЅІЇЈЉЊЋЌЎЏҐабвгдежзийклмнопрстуфхцчшщъыьэюяёђѓєѕіїјљњћќўџґ";

export const virtualKeyboardLetters = Object.values(keyboardLayouts)
  .map((lang) => lang.default)
  .flat()
  .join("")
  .replace(/[ =]/g, "");
export const regexIsLetter = new RegExp(`[${virtualKeyboardLetters}]`, "i");

// TODO: THIS SHOULD BE A BACKEND ENDPOINT
export const timeLeftTillNextSelection = (): Duration => {
  const dtNow = DateTime.utc();

  const currentHour = dtNow.startOf("hour").hour;

  const haveToAdd =
    sharedSettingsBackendAndFrontend.selectionEveryXHours -
    (currentHour % sharedSettingsBackendAndFrontend.selectionEveryXHours);

  const leftTillFuture = dtNow.plus({ hours: haveToAdd }).startOf("hour").diff(dtNow);

  return leftTillFuture;
};

export const getAudioReadableStream = async (
  userToken: string,
  wordId: number,
  audioLanguage: ForeignLanguages
): Promise<ReadableStream> => {
  const data = await fetch(
    `${baseUrl}/audio/get-audio?wordId=${wordId}&audioLanguage=${audioLanguage}`,
    {
      headers: { Authorization: `Bearer ${userToken}` },
    }
  );
  const stream = data?.body;
  const reader = stream && stream.getReader();
  return new ReadableStream({
    start(controller) {
      function pump(): Promise<ReadableStream | undefined> | undefined {
        return reader?.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return;
          }
          controller.enqueue(value);
          return pump();
        });
      }
      return pump();
    },
  });
};

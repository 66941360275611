import { Slice, createSlice } from "@reduxjs/toolkit";

type FirebaseAuthData = {
  userId: string | null;
  userToken: string | null;
  userEmail: string | null;
};

type UserState = FirebaseAuthData & {
  isUserLoading: boolean;
  isUserError: boolean;
  userError: Error | null;
};

const initialState: UserState = {
  userId: null,
  userToken: null,
  userEmail: null,
  isUserLoading: true,
  isUserError: false,
  userError: null,
};

export const userSlice: Slice<
  UserState,
  {
    login: (
      state: UserState,
      {
        payload,
      }: {
        payload: FirebaseAuthData;
      }
    ) => void;
    logout: (state: UserState) => void;
    setError: (
      state: UserState,
      {
        payload,
      }: {
        payload: Error;
      }
    ) => void;
  },
  "user"
> = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    login: (state, { payload }) => {
      state.userId = payload.userId;
      state.userToken = payload.userToken;
      state.userEmail = payload.userEmail;
      state.isUserLoading = false;
      state.isUserError = false;
      state.userError = null;
    },
    logout: (state) => {
      state.userId = null;
      state.userToken = null;
      state.userEmail = null;
      state.isUserLoading = false;
      state.isUserError = false;
      state.userError = null;
    },
    setError: (state, { payload }) => {
      state.userId = null;
      state.userToken = null;
      state.userEmail = null;
      state.isUserLoading = false;
      state.isUserError = true;
      state.userError = payload;
    },
  },
});

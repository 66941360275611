import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "./store";
import { baseUrl, projectSettings } from "../app/config";
import {
  AllUserProgress,
  GetAllUserProgressQueryDto,
  GetNextFlashcardQueryDto,
  SaveCorrectionBodyDto,
  SaveProgressBodyDto,
  SimpleFlashcard,
  UserSettings,
  LeaderboardData,
  AddSuggestionBodyDto,
  UpvoteSuggestionBodyDto,
  Suggestion,
  SetLearningGoalDto,
  LearningGoal,
  GetLearningGoalDto,
} from "../app/types";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Leaderboard", "LearnedFlashcards", "Suggestions", "Goal"],
  endpoints: (builder) => ({
    /*** LESSON ***/

    getNextFlashcard: builder.query<SimpleFlashcard, GetNextFlashcardQueryDto>({
      query: ({ nativeLanguage, foreignLanguage, lessonType }) => ({
        url: "lesson/get-next-flashcard",
        method: "GET",
        params: {
          nativeLanguage,
          foreignLanguage,
          lessonType
        },
      }),
    }),

    saveFlashcardProgress: builder.mutation<SimpleFlashcard, SaveProgressBodyDto>({
      query: ({ wordId, numberOfMistakes, nativeLanguage, foreignLanguage, lessonType }) => ({
        url: "lesson/save-progress-simple",
        method: "POST",
        body: { wordId, numberOfMistakes, nativeLanguage, foreignLanguage, lessonType },
      }),
      invalidatesTags: ["LearnedFlashcards"],
      async onQueryStarted(body, { getState, dispatch, queryFulfilled }) {
        const { lessonType } = body;
        // Get languages from user settings
        const userSettings = (getState() as RootState).api.queries["getUserSettings(undefined)"]
          ?.data as UserSettings;
        if (
          !userSettings?.nativeLanguage ||
          !userSettings?.foreignLanguage ||
          !Object.keys(projectSettings.availableLanguages).includes(userSettings.nativeLanguage) ||
          !Object.keys(projectSettings.availableLanguages).includes(userSettings.foreignLanguage)
        ) {
          return;
        }

        // Do a pessimistic update
        try {
          const { data: nextFlashcard } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              "getNextFlashcard",
              {
                nativeLanguage: userSettings.nativeLanguage,
                foreignLanguage: userSettings.foreignLanguage,
                lessonType,
              },
              (draft) => {
                Object.assign(draft, nextFlashcard);
              }
            )
          );
        } catch {}
      },
    }),

    getAllUserProgress: builder.query<AllUserProgress, GetAllUserProgressQueryDto>({
      query: ({ paginationPage, nativeLanguage, foreignLanguage }) => ({
        url: `lesson/get-all-user-progress`,
        method: "GET",
        params: { paginationPage, nativeLanguage, foreignLanguage },
      }),
      providesTags: ["LearnedFlashcards", "Leaderboard"],
    }),

    saveCorrection: builder.mutation<void, SaveCorrectionBodyDto>({
      query: ({ wordId, nativeLanguage, foreignLanguage, correctionText }) => ({
        url: `lesson/save-correction`,
        method: "POST",
        body: {
          wordId,
          nativeLanguage,
          foreignLanguage,
          correctionText,
        },
      }),
    }),

    /*** USER ***/

    getLeaderboard: builder.query<LeaderboardData[], void>({
      query: () => ({
        url: "user/get-leaderboard",
        method: "GET",
      }),
      providesTags: ["Leaderboard"],
    }),

    /*** USER SETTINGS ***/

    saveUserSettings: builder.mutation<UserSettings, Partial<UserSettings>>({
      query: (userSettings) => ({
        url: "user-settings/save-user-settings",
        method: "POST",
        body: userSettings,
      }),
      invalidatesTags: ["Leaderboard"],
      async onQueryStarted(_userSettings, { dispatch, queryFulfilled }) {
        // Pessimistic update
        try {
          const { data: updatedUserSettings } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getUserSettings", undefined, (draft) => {
              Object.assign(draft, updatedUserSettings);
            })
          );
        } catch {}
      },
    }),

    getUserSettings: builder.query<UserSettings, void>({
      query: () => ({
        url: "user-settings/get-user-settings",
        method: "GET",
      }),
    }),

    registerIfNew: builder.mutation<void, void>({
      query: () => ({
        url: "user-settings/register-if-new", // TODO: This should be 'user' endpoint instead of 'user-settings'
        method: "POST",
      }),
    }),

    /*** SUGGESTIONS ***/

    getSuggestions: builder.query<Suggestion[], void>({
      query: () => ({
        url: "suggestions/get-suggestions",
        method: "GET",
      }),
      providesTags: ["Suggestions"],
    }),

    addSuggestion: builder.mutation<void, AddSuggestionBodyDto>({
      query: ({ suggestion }) => ({
        url: "suggestions/add-suggestion",
        method: "POST",
        body: { suggestion },
      }),
      invalidatesTags: ["Suggestions"],
    }),

    upvoteSuggestion: builder.mutation<void, UpvoteSuggestionBodyDto>({
      query: ({ suggestionId }) => ({
        url: "suggestions/upvote-suggestion",
        method: "POST",
        body: { suggestionId },
      }),
      invalidatesTags: ["Suggestions"],
    }),

    /*** LEARNING GOAL ***/

    getLearningGoal: builder.query<LearningGoal, GetLearningGoalDto>({
      query: ({ nativeLanguage, foreignLanguage }) => ({
        url: "goals",
        method: "GET",
        params: { nativeLanguage, foreignLanguage },
      }),
      providesTags: ["Goal"],
    }),

    setLearningGoal: builder.mutation<void, SetLearningGoalDto>({
      query: ({ goal, nativeLanguage, foreignLanguage }) => ({
        url: "goals",
        method: "POST",
        body: { goal, nativeLanguage, foreignLanguage },
      }),
      invalidatesTags: ["Goal"],
    }),

  }),
});

export const {
  useGetLeaderboardQuery,
  useGetNextFlashcardQuery,
  useSaveFlashcardProgressMutation,
  useGetAllUserProgressQuery,
  useSaveCorrectionMutation,
  useSaveUserSettingsMutation,
  useGetUserSettingsQuery,
  useRegisterIfNewMutation,
  useGetSuggestionsQuery,
  useAddSuggestionMutation,
  useUpvoteSuggestionMutation,
  useGetLearningGoalQuery,
  useSetLearningGoalMutation,
} = api;

import { keyframes } from "@mui/system";

export const wrapper = {
  display: "inline-block",
};

export const box = {
  height: "40px",
  fontSize: "25px",
  lineHeight: "25px",
  margin: "2px",
  textTransform: "uppercase",
  fontFamily: "monospace",
};

export const boxMinWidth = {
  minWidth: "20px",
};

export const boxFixedWidth = {
  width: "40px",
};

export const boxBorder = {
  border: "2px solid lightgray",
};

export const charNew = {
  color: "#a2a2a2",
};

export const charCorrect = {
  backgroundColor: "#dfffa0",
};

export const charIncorrect = {
  color: "#a2a2a2",
  border: "#f3c0b7 2px solid",
};

export const charIncorrectFixed = {
  backgroundColor: "#ffebce",
};

export const charExempted = {};

export const blinkingEffect = keyframes`
  50% {
    background-color: lightgray;
  }
`;

export const charActive = {
  animation: `${blinkingEffect} 1.2s linear infinite alternate`,
};

export const centered = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import { useUser } from "../../hooks/useUser";
import { useGetAllUserProgressQuery } from "../../redux/api";
import { Button, ListItemSecondaryAction } from "@mui/material";
import { NavLink } from "react-router-dom";

export const ClaimedWords: React.FC = () => {
  const { t } = useTranslation();
  const { nativeLanguage, foreignLanguage } = useUser();

  const [paginationPage, setPaginationPage] = useState<number>(1);
  const handlePaginationChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPaginationPage(newPage);
  };

  const { data: progressResult, isLoading: isProgressLoading } = useGetAllUserProgressQuery(
    !nativeLanguage || !foreignLanguage
      ? skipToken
      : {
          paginationPage,
          nativeLanguage,
          foreignLanguage,
        }
  );

  if (isProgressLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Typography variant="h5">{t("claimedWords.title")}</Typography>
      {!progressResult?.userProgress?.length ? (
        <Typography variant="body1">{t("claimedWords.noWords")}</Typography>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t("claimedWords.words")}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t("claimedWords.strength")}</Typography>
          </Box>
          <List dense={true}>
            {progressResult?.userProgress?.map((wordProgress) => (
              <ListItem
                key={wordProgress.wordId}
                sx={{ padding: 0 }}
              >
                <ListItemText
                  primary={wordProgress.foreignLanguage}
                  secondary={wordProgress.nativeLanguage}
                />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  <Box sx={{ width: 50 }}>
                    {typeof wordProgress.memoryStrength !== "undefined" ? (
                      <LinearProgress
                        variant="determinate"
                        sx={{ right: 0 }}
                        color={
                          wordProgress.memoryStrength > 75
                            ? "info"
                            : wordProgress.memoryStrength > 25
                              ? "warning"
                              : "error"
                        }
                        value={wordProgress.memoryStrength}
                      />
                    ) : null}
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            </List>
            {!!progressResult?.totalPages && progressResult?.totalPages > 1 && (
              <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  count={progressResult?.totalPages}
                  page={paginationPage}
                  onChange={handlePaginationChange}
                />
            </Box>
          )}
          <Button component={NavLink} to="/learn/repeat" variant="contained" >{t("claimedWords.repeatWords")}</Button>
        </>
      )
      }
    </>
  );
};

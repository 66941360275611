import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";

type Props = {
  children: React.ReactNode;
};

export const ProtectedRoute: FC<Props> = ({ children }) => {
  const { userToken, isUserLoading } = useAppSelector((state) => state.user);

  // TODO: We should have our own user ID in the database and the uid supplied by Firebase should be a separate field
  // Here, it should be checked whether actually the user exists in our database
  // Because it's possible that they have Firebase account but we removed them from our database

  if (isUserLoading) {
    return null;
  }

  if (!userToken) {
    return <Navigate to="/sign-in" replace />;
  }

  return <>{children}</>;
};

import { FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/userSlice";
import { auth, onAuthStateChanged } from "../../app/firebase";
import { useRegisterIfNewMutation } from "../../redux/api";

type Props = {
  children: ReactNode;
};

export const FirebaseAuth: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const [registerIfNew] = useRegisterIfNewMutation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (userAuth) => {
        if (userAuth) {
          const fetchedToken = await userAuth.getIdToken();

          dispatch(
            userSlice.actions.login({
              userId: userAuth.uid,
              userToken: fetchedToken,
              userEmail: userAuth.email,
            })
          );

          await registerIfNew();

          // Lucky Orange
          window.LOQ = window.LOQ || [];
          window.LOQ.push([
            "ready",
            function (LO: any) {
              LO.$internal.ready("privacy").then(function () {
                LO.privacy.setConsentStatus(true); // TODO: This should be accepted by the user (GDPR)
              });
              LO.$internal.ready("visitor").then(function () {
                LO.visitor.identify({ email: userAuth.email });
              });
            },
          ]);
        } else {
          dispatch(userSlice.actions.logout());
        }
      },
      userSlice.actions.setError
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { useTranslation } from "react-i18next";
import { useAddSuggestionMutation } from "../../redux/api";

export const SuggestFeature = () => {
  const { t } = useTranslation();
  const [isFormVisible, setIsFormVisible] = React.useState(false);

  const [addSuggestion, { isLoading: isLoadingSuggestion }] = useAddSuggestionMutation();

  const handleShowContactForm = () => {
    setIsFormVisible(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const suggestion = event.currentTarget.suggestion.value;
    if (!suggestion) return;
    await addSuggestion({ suggestion });
    setIsFormVisible(false);
  };

  return (
    <Box my={2}>
      {isFormVisible ? (
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            size="small"
            margin="normal"
            type="text"
            required
            label={t("suggestions.writeYourSuggestion")}
            name="suggestion"
            sx={{ mr: 1 }}
            fullWidth
          />
          <Button type="submit" fullWidth variant="contained" disabled={isLoadingSuggestion}>
            {t("suggestions.submitSuggestion")}
          </Button>
        </Box>
      ) : (
        <Button size="small" onClick={handleShowContactForm} variant="outlined">
            {t("suggestions.addSuggestion")}
        </Button>
      )}
    </Box>
  );
};

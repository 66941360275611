import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import Box from "@mui/system/Box";
import { useTranslation } from "react-i18next";
import { useGetLearningGoalQuery, useSetLearningGoalMutation } from "../../redux/api";
import { CircularProgress, Typography } from "@mui/material";
import { useUser } from "../../hooks/useUser";

export const LearningGoal = () => {
  const { t } = useTranslation();
  const [isFormVisible, setIsFormVisible] = React.useState(false);

  const { nativeLanguage, foreignLanguage } = useUser()
  const { data: learningGoal, isLoading: isLearningGoalLoading } = useGetLearningGoalQuery({ nativeLanguage, foreignLanguage });

  const [setLearningGoal, { isLoading: isLoadingSetLearningGoal }] = useSetLearningGoalMutation();

  const handleShowContactForm = () => {
    setIsFormVisible(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const goal = event.currentTarget.goal.value;
    if (!goal || !foreignLanguage) return;
    await setLearningGoal({ goal, nativeLanguage, foreignLanguage });
    setIsFormVisible(false);
  };

  if (isLearningGoalLoading) {
    return (
      <Box sx={{ width: "100%", my: 4 }} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("learningGoal.title")}
      </Typography>
      <Typography variant="body1" gutterBottom textAlign="center">
        {t("learningGoal.description")}
      </Typography>
      {isFormVisible || !learningGoal?.goal ? (
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            size="small"
            margin="normal"
            type="text"
            required
            label={t("learningGoal.writeYourGoal")}
            name="goal"
            sx={{ mb: 3 }}
            fullWidth
          />

          <LoadingButton type="submit" variant="contained" loading={isLoadingSetLearningGoal} >
            <span>{t("learningGoal.submitGoal")}</span>
          </LoadingButton>
        </Box>
      ) : (
          <Box sx={{ textAlign: 'center', my: 3 }}>
            <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>{t("learningGoal.yourGoalIs")}</Typography>
          <Typography variant="overline" gutterBottom>{learningGoal?.goal}</Typography>
          <Box>
            <Button size="small" onClick={handleShowContactForm} variant="outlined">
                {t("learningGoal.changeGoal")}
            </Button>
          </Box>
            {learningGoal?.categories?.length && (
              <Box sx={{ my: 3 }}>
                <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>{t("learningGoal.goalCategories")}</Typography>
                {learningGoal?.categories.map(category => (
                  <Typography variant="body1" gutterBottom>{category}</Typography>
                ))}
              </Box>
            )}
        </Box>
      )}
    </>)
};

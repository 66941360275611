import { Route, Routes, BrowserRouter } from "react-router-dom";

import { Home } from "../pages/Home/Home";
import { SignIn } from "../pages/SignIn/SignIn";
import { Lesson } from "../pages/Lesson/Lesson";
import { Account } from "../pages/Account/Account";
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { Leaderboard } from "../pages/Leaderboard/Leaderboard";
import { AI } from "../pages/AI/AI";
import { AppRoute } from "./types";



export const appRoutes: AppRoute[] = [
  {
    id: "home",
    path: "/",
    element: <Home />,
  },
  {
    id: "signIn",
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    id: 'learn',
    path: "/learn/:lessonType",
    element: (
      <ProtectedRoute>
        <Lesson />
      </ProtectedRoute>
    ),
    nav: '/learn/new'
  },
  {
    id: 'compete',
    path: "/compete",
    element: (
      <ProtectedRoute>
        <Leaderboard />
      </ProtectedRoute>
    ),
    nav: '/compete'
  },
  {
    id: 'adjust',
    path: "/adjust",
    element: (
      <ProtectedRoute>
        <Account />
      </ProtectedRoute>
    ),
    nav: '/adjust'
  },
  {
    id: 'ai',
    path: "/ai",
    element: (
      <ProtectedRoute>
        <AI />
      </ProtectedRoute>
    ),
    nav: '/ai'
  },
]

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {appRoutes.map((route) => (
          <Route key={route.id} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

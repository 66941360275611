import React from "react";

import Box from "@mui/material/Box";

import { VirtualKeyboardContextType } from "../../app/types";

export const VirtualKeyboardContext = React.createContext<VirtualKeyboardContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

export const KeyboardWrapper: React.FC<Props> = ({ children }) => {

    const [keyboardHeight, setKeyboardHeight] = React.useState(0);

    return (
        <Box paddingBottom={`${keyboardHeight}px`}>
            <VirtualKeyboardContext.Provider value={{ setKeyboardHeight }}>
                {children}
            </VirtualKeyboardContext.Provider>
        </Box>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { projectSettings } from "../../app/config";
import { translations } from "../../app/translations";
import { useUser } from "../../hooks/useUser";
import { NativeLanguages, ForeignLanguages, UserSettings } from "../../app/types";
import { useSaveUserSettingsMutation } from "../../redux/api";
import { SimpleError } from "../../components/SimpleError/SimpleError";

export const SelectLanguages: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nativeLanguage, foreignLanguage } = useUser();
  const [saveUserSettings, { isLoading }] = useSaveUserSettingsMutation();

  const [selectedLanguages, setSelectedLanguages] = React.useState<{
    native: NativeLanguages;
    foreign: ForeignLanguages | null;
  }>({
    native: nativeLanguage,
    foreign: foreignLanguage,
  });

  const [formError, setFormError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (nativeLanguage && foreignLanguage) {
      setSelectedLanguages({
        native: nativeLanguage,
        foreign: foreignLanguage,
      });
    }
  }, [foreignLanguage, nativeLanguage]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setFormError(null);
    event.preventDefault();

    if (!selectedLanguages.native || !selectedLanguages.foreign) {
      setFormError("Please select native and foreign language.");
      return;
    }

    await saveUserSettings({
      nativeLanguage: selectedLanguages.native,
      foreignLanguage: selectedLanguages.foreign,
    });

    navigate("/");
  };

  const handleLanguageUpdate = (event: SelectChangeEvent<NativeLanguages | ForeignLanguages>) => {
    setFormError(null);

    const lng = event.target.value as NativeLanguages | ForeignLanguages;
    const type = event.target.name as "native" | "foreign";

    let updatedUserSettings: Partial<UserSettings> = {};

    const isLanguageAvailable =
      Object.keys(projectSettings.availableLanguages).includes(lng) && // We offer it
      (type === "foreign" || Object.keys(translations).includes(lng)); // We actually have translations for it
    if (!isLanguageAvailable) {
      const errorMessage = `Language ${lng} is not available in project settings.`;
      console.warn(errorMessage);
      setFormError(errorMessage);
      return;
    }

    if (type === "foreign") {
      updatedUserSettings.foreignLanguage = lng as ForeignLanguages;
    }

    if (type === "native") {
      updatedUserSettings.nativeLanguage = lng as NativeLanguages;
    }

    setSelectedLanguages((prev) => ({
      ...prev,
      [type]: lng,
    }));
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("account.languages")}
      </Typography>
      <Typography variant="body1" gutterBottom textAlign="center">
        {t("account.languagesDescription")}
      </Typography>
      {formError && <SimpleError error={formError} />}
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 3,
          }}
        >
          <FormControl sx={{ flex: 1, mr: 2 }}>
            <InputLabel id="native-language-label">
              {t("account.nativeLanguage")}
            </InputLabel>
            <Select
              labelId="native-language-label"
              name="native"
              value={selectedLanguages.native}
              label={t("account.nativeLanguage")}
              onChange={handleLanguageUpdate}
              displayEmpty
              required
            >
              {Object.keys(translations).map((lng) => (
                <MenuItem key={lng} value={lng}>
                  {
                    projectSettings.availableLanguages[
                      lng as keyof typeof projectSettings.availableLanguages
                    ].nativeName
                  }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel id="foreign-language-label">
              {t("account.foreignLanguage")}
            </InputLabel>
            <Select
              labelId="foreign-language-label"
              value={selectedLanguages.foreign || ""}
              label={t("account.foreignLanguage")}
              name="foreign"
              onChange={handleLanguageUpdate}
              displayEmpty
              required
            >
              {Object.keys(projectSettings.availableLanguages).map((lng) => (
                <MenuItem key={lng} value={lng}>
                  {
                    projectSettings.availableLanguages[
                      lng as keyof typeof projectSettings.availableLanguages
                    ].nativeName
                  }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? t("general.loading") : t("general.save")}
        </Button>
      </Box>
    </>
  )
}
import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { logout } from "../../app/firebase";
import { useAppSelector } from "../../hooks/useRedux";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { Suggestions } from "../../components/Suggestions/Suggestions";
import { SelectLanguages } from "../../components/SelectLanguages/SelectLanguages";

export const Account: React.FC = () => {
  const { t } = useTranslation();
  const { userEmail } = useAppSelector((state) => state.user);

  return (
    <PageLayout>

      <SelectLanguages />

      <Box sx={{ width: "100%", my: 4 }}>
        <Divider />
      </Box>

      <Suggestions />

      <Box sx={{ width: "100%", my: 4 }}>
        <Divider />
      </Box>

      <Typography component="div" variant="body1" sx={{ my: 1 }}>
        {userEmail}
      </Typography>
      <Button size="small" onClick={logout} variant="outlined" sx={{ my: 1 }}>
        {t("general.signOut")}
      </Button>

    </PageLayout>
  );
};

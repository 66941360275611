export enum StorageNames {
  PASSWORDLESS_EMAIL = "passwordlessEmail",
}

export type UserLanguages = {
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
  isForeignLanguageSet: boolean;
  isUserLoggedIn: boolean;
  isUserLoading: boolean;
  isUserSettingsLoading: boolean;
};

export enum CharType {
  NEW,
  CORRECT,
  INCORRECT,
  INCORRECT_FIXED,
  EXEMPTED,
}

export type CharInfo = {
  letter: string;
  type: CharType;
  isActive: boolean;
  isHidden: boolean;
};

export type VirtualKeyboardContextType = {
  setKeyboardHeight: React.Dispatch<React.SetStateAction<number>>;
};

/* DTOs */

export type GetNextFlashcardQueryDto = {
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
  lessonType: LessonType;
};

export type SaveProgressBodyDto = {
  wordId: number;
  numberOfMistakes: number;
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
  lessonType: LessonType;
};

export type GetAllUserProgressQueryDto = {
  paginationPage: number;
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
};

export type SaveCorrectionBodyDto = {
  wordId: number;
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
  correctionText: string;
};

export type AddSuggestionBodyDto = {
  suggestion: string;
};

export type UpvoteSuggestionBodyDto = {
  suggestionId: number;
};

export type GetLearningGoalDto = {
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
};

export type SetLearningGoalDto = {
  goal: string;
  nativeLanguage: NativeLanguages;
  foreignLanguage: ForeignLanguages;
};

export type Suggestion = {
  id: number;
  suggestion: string;
  score: number;
  isOwner: boolean;
  hasVoted: boolean;
};

export type AppRoute = {
  id: string;
  path: string;
  element: React.ReactNode;
  nav?: string;
};

/* SAME FRONTEND AND BACKEND TYPES */
export enum LessonType {
  REPEAT = "repeat",
  NEW = "new",
}

export type SimpleFlashcard = {
  progressId: number;
  wordId: number;
  nativeLanguage: string;
  foreignLanguage: string;
  isFinished?: boolean;
  memoryStrength?: number;
};

export enum NativeLanguages {
  EN = "en",
  SK = "sk",
  CS = "cs",
  BG = "bg",
}

export enum ForeignLanguages {
  EN = "en",
  DE = "de",
  ES = "es",
  IT = "it",
  FR = "fr",
  RU = "ru",
  SK = "sk",
  CS = "cs",
  BG = "bg",
}

export type AllUserProgress = {
  userProgress: SimpleFlashcard[];
  totalPages: number;
};

export type UserSettings = {
  nativeLanguage: NativeLanguages | null;
  foreignLanguage: ForeignLanguages | null;
  finishedWalktours: Array<string>;
  continuousMode: boolean;
  leaderboardName: string;
};

export type LeaderboardData = {
  isThisUser: boolean;
  leaderboardName: string;
  memoryStrength: number;
  numberOfWords: number;
};

export type LearningGoal = {
  goal: string;
  categories: string[];
};


import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { useAppSelector } from "../../hooks/useRedux";
import { useUser } from "../../hooks/useUser";
import { SimpleError } from "../../components/SimpleError/SimpleError";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { sendPasswordlessEmail, signInWithGoogle } from "../../app/firebase";

export const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nativeLanguage } = useUser();
  const { userToken, isUserLoading, isUserError, userError } = useAppSelector(
    (state) => state.user
  );

  // Handle email submit
  const [email, setEmail] = React.useState<string>("");
  const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendPasswordlessEmail(email, nativeLanguage);
    setIsEmailSent(true);
  };

  React.useEffect(() => {
    const navigateToHome = async () => {
      navigate("/");
    };
    if (userToken) {
      navigateToHome();
    }
  }, [navigate, userToken]);

  if (isUserLoading) {
    return (
      <PageLayout>
        <CircularProgress />
      </PageLayout>
    );
  }

  if (isUserError) {
    return (
      <PageLayout>
        <SimpleError error={userError || null} />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Typography variant="h5" textAlign="center" gutterBottom>
        {t("general.signIn")}
      </Typography>
      <Typography variant="body1" textAlign="center" mb={3}>
        {t("signIn.disclaimer")}
      </Typography>
      {isEmailSent ? (
        <Typography component="p" variant="body1" textAlign="center">
          {t("signIn.emailSent")}
        </Typography>
      ) : (
        <Box textAlign="center">
          <Button type="submit" fullWidth variant="contained" onClick={signInWithGoogle}>
            {t("signIn.signInWithGoogle")}
          </Button>
          <Divider sx={{ width: "100%", my: 4 }}>{t("signIn.or")}</Divider>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              type="email"
              required
              fullWidth
              id="email"
                label={t("general.email")}
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ my: 2 }}>
              {t("signIn.signInWithEmail")}
            </Button>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};

import React, { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Letter } from "../Letter/Letter";
import { useAppSelector } from "../../hooks/useRedux";
import { useUser } from "../../hooks/useUser";
import VirtualKeyboard from "../Keyboard/Keyboard";
import { pollyVoices, projectSettings } from "../../app/config";
import { CharInfo, CharType, SimpleFlashcard } from "../../app/types";
import { useGetUserSettingsQuery } from "../../redux/api";
import { getAudioReadableStream, regexIsLetter } from "../../app/utils";
import { CorrectionModal } from "../CorrectionModal/CorrectionModal";

type Props = {
  currentFlashcard: SimpleFlashcard | undefined;
  numberOfMistakes: number | null;
  setNumberOfMistakes: (numberOfMistakes: number) => void;
  saveAndMoveToNextFlashcard: () => Promise<void>;
};

export const Blackboard: FC<Props> = ({
  currentFlashcard,
  numberOfMistakes,
  setNumberOfMistakes,
  saveAndMoveToNextFlashcard,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { foreignLanguage } = useUser();
  const { userToken } = useAppSelector((state) => state.user);

  const [lastLetterCorrect, setLastLetterCorrect] = React.useState<boolean>(true);
  const [letters, setLetters] = React.useState<Array<CharInfo>>([]);
  const question = currentFlashcard?.nativeLanguage;

  const { data: userSettings } = useGetUserSettingsQuery(!userToken ? skipToken : undefined);

  const playAudio = React.useCallback(async () => {
    if (!userToken || !currentFlashcard?.wordId || !foreignLanguage) {
      return;
    }

    if (!Object.keys(pollyVoices).includes(foreignLanguage)) {
      return;
    }

    getAudioReadableStream(userToken, currentFlashcard.wordId, foreignLanguage)
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        const startingAudioElement = new Audio(url);
        startingAudioElement.play();
      })
      .catch((err) => console.error(err));
  }, [currentFlashcard?.wordId, foreignLanguage, userToken]);

  // Updating the input and data with the current flashcard info
  React.useEffect(() => {
    if (currentFlashcard) {
      setLetters(
        currentFlashcard.foreignLanguage.split("").map((char: string, index: number) => ({
          type: regexIsLetter.test(char) ? CharType.NEW : CharType.EXEMPTED, // We want to skip over the non-letter characters
          letter: char,
          isActive: index === 0, // A new flashcard has been loaded, make first letter active. TODO: What if the first char is a diacritic? Probably has to be handled by a recurring function to find the first active letter.
          isHidden: regexIsLetter.test(char) ? true : false, // All letters will be hidden when new word is loaded
        }))
      );
    }
  }, [currentFlashcard]);

  // Handle typing
  const handleTyping = useCallback(
    (typedValue: string) => {
      if (projectSettings.ignoredKeys.includes(typedValue)) {
        return;
      }

      const typedValueInLowerCase = typedValue.toLowerCase();

      if (letters?.length === 0) {
        return;
      }

      const activeIndex = letters.findIndex((char) => char.isActive);
      if (activeIndex === -1 || activeIndex >= letters.length) {
        return;
      }

      const diacriticsChar = letters[activeIndex].letter.toLowerCase();
      const isCorrectAnswer =
        typedValueInLowerCase === letters[activeIndex].letter.toLowerCase() ||
        projectSettings.diacriticChars[
          diacriticsChar as keyof typeof projectSettings.diacriticChars
        ] === typedValueInLowerCase;
      const hadHadMistake = letters[activeIndex].type === CharType.INCORRECT;

      // Initiate a search for the next active letter (or the same letter stays active if it was incorrect)
      let nextActiveIndex: null | number = null;

      // Update the styles of the letters
      const newLetters = letters.map((char, index): CharInfo => {
        if (index === activeIndex) {
          if (isCorrectAnswer) {
            return {
              ...char,
              type: char.type === CharType.INCORRECT ? CharType.INCORRECT_FIXED : CharType.CORRECT,
              isHidden: false,
              isActive: false,
            };
          }
          nextActiveIndex = index;
          return { ...char, type: CharType.INCORRECT, isHidden: false, isActive: true };
        }

        if (index > activeIndex) {
          if (isCorrectAnswer) {
            // If the answer was correct, we need to find the next active letter (if there is one)

            const isLetter = regexIsLetter.test(letters[activeIndex].letter);
            if (nextActiveIndex === null && isLetter && char.type === CharType.NEW) {
              nextActiveIndex = index;
              return { ...char, isActive: true };
            }
          }

          if (!isCorrectAnswer && !hadHadMistake) {
            // If the answer was incorrect (for the first time)
            // show half of the remaining letters (if this is the first time making a mistake on this index)
            return {
              ...char,
              isHidden:
                lastLetterCorrect && char.isHidden && Math.random() > 0.5 ? false : char.isHidden,
            };
          }
        }

        return char;
      });

      if (!isCorrectAnswer && regexIsLetter.test(letters[activeIndex].letter)) {
        setLastLetterCorrect(false);
      } else {
        setLastLetterCorrect(true);
      }

      // Finished the whole word
      if (nextActiveIndex === null) {
        playAudio();
        const numberOfMistakes = newLetters.filter(
          (char) => char.type === CharType.INCORRECT_FIXED
        ).length;
        setNumberOfMistakes(numberOfMistakes);
      }

      // Update the state
      setLetters(newLetters);
    },
    [letters, lastLetterCorrect, playAudio, setNumberOfMistakes]
  );

  // Handle physical typing
  React.useEffect(() => {
    const handlePhysicalTyping = async (event: KeyboardEvent) => {
      event.preventDefault();

      // Write the letter (we cannot use physicalKeyboardHighlightPress={true} in the VirtualKeyboard component due to a bug in the react-simple-keyboard which doesn't unsubscribe when unmounting)
      handleTyping(event.key);
    };
    document.addEventListener("keyup", handlePhysicalTyping); // Why to use keyup: https://thisthat.dev/keydown-vs-keypress-vs-keyup/
    return () => {
      document.removeEventListener("keyup", handlePhysicalTyping);
    };
  }, [handleTyping, numberOfMistakes, saveAndMoveToNextFlashcard, userSettings?.finishedWalktours]);

  if (!currentFlashcard || !theme || !t || !foreignLanguage || !userToken) {
    return null;
  }

  return (
    <Box width="100%" marginY={theme.spacing(3)}>
      <Typography variant="body1" component="p" gutterBottom textAlign="center">
        {t("lesson.translateWithManyMistakes")}
      </Typography>
      <Box width="100%" marginY={theme.spacing(3)}>
        <Divider />
      </Box>
      <Typography
        variant="h5"
        component="div"
        gutterBottom
        textAlign="center"
      >
        {question ? question : t("general.thereWasAnError")}
      </Typography>
      <Box marginY={theme.spacing(3)} textAlign="center">
        {letters.map((char, index) => (
          <Letter
            key={index}
            letter={char.letter}
            type={char.type}
            isActive={char.isActive}
            isHidden={char.isHidden}
          />
        ))}
      </Box>

      {numberOfMistakes !== null ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", m: 3 }}>
            <CorrectionModal wordId={currentFlashcard.wordId} />
            <Tooltip title={t("lesson.nextTooltip")}>
              <Button
                variant="contained"
                onClick={saveAndMoveToNextFlashcard}
                autoFocus
              >
                {t("lesson.next")} <ArrowForwardIcon sx={{ ml: 1 }} />
              </Button>
            </Tooltip>
          </Box>
        </>
      ) : null}

      <VirtualKeyboard handleButtonRelease={handleTyping} foreignLanguage={foreignLanguage} />
    </Box>
  );
};

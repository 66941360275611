import React from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";

import { PageLayout } from "../../components/PageLayout/PageLayout";
import { LeaderboardData } from "../../app/types";
import { useGetLeaderboardQuery, useGetUserSettingsQuery } from "../../redux/api";
import { LeadeboardTableHead, descendingComparator } from "../../components/LeaderboardTableHead/LeaderboardTableHead";
import { LeaderboardName } from "../../components/LeaderboardName/LeaderboardName";
import { CircularProgress } from "@mui/material";

export const Leaderboard: React.FC = () => {
  const { t } = useTranslation();
  const { data: userSettings } = useGetUserSettingsQuery();
  const { data: rows, isLoading: isLeaderboardLoading } = useGetLeaderboardQuery(
    !!userSettings?.leaderboardName ? undefined : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const [orderBy, setOrderBy] = React.useState<keyof LeaderboardData>("numberOfWords");

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof LeaderboardData) => {
    setOrderBy(property);
  };

  const sortedRows = React.useMemo(() => {
    if (rows?.length) {
      return [...rows].sort((a, b) => descendingComparator(a, b, orderBy));
    }
    return [];
  }, [rows, orderBy]);

  return (
    <PageLayout>
      <Typography variant="h5" gutterBottom>
        {t("leaderboard.leaderboard")}
      </Typography>
      {isLeaderboardLoading ? <CircularProgress /> :
        !!userSettings?.leaderboardName === false ? <LeaderboardName /> : !sortedRows.length ? (
          <Typography variant="body1">{t("leaderboard.noEntries")}</Typography>
      ) : (
        <TableContainer>
          <Table size="small">
            <LeadeboardTableHead orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {sortedRows.map((row) => {
                return (
                  <TableRow
                    key={row.leaderboardName}
                    sx={{ "td, th": { border: 0, maxWidth: 100, overflow: "hidden" } }}
                    selected={row.isThisUser}
                  >
                    <TableCell component="th" scope="row">
                      {row.leaderboardName}
                    </TableCell>
                    <TableCell align="right">{row.numberOfWords}</TableCell>
                    <TableCell align="right">{row.memoryStrength}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )
      }

    </PageLayout>
  );
};
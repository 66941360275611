export const sharedSettingsBackendAndFrontend = {
  selectionEveryXHours: 4,
};

export const projectSettings = {
  projectName: "Swift Habit",
  diacriticChars: {
    é: "e",
    è: "e",
    ě: "e",
    ê: "e",
    í: "i",
    ì: "i",
    î: "i",
    á: "a",
    à: "a",
    ä: "a",
    â: "a",
    ò: "o",
    ó: "o",
    ö: "o",
    ô: "o",
    ú: "u",
    ù: "u",
    ü: "u",
    ů: "u",
    û: "u",
    ý: "y",
    ß: "s",
    ñ: "n",
    ç: "c",
    ľ: "l",
    š: "s",
    č: "c",
    ť: "t",
    ž: "z",
    ň: "n",
    ř: "r",
  },
  availableLanguages: {
    en: { nativeName: "English" },
    de: { nativeName: "Deutsch" },
    es: { nativeName: "Español" },
    it: { nativeName: "Italiano" },
    fr: { nativeName: "Français" },
    ru: { nativeName: "Русский" },
    sk: { nativeName: "Slovenčina" },
    cs: { nativeName: "Čeština" },
    bg: { nativeName: "Български" },
  },
  ignoredKeys: [
    " ",
    "Unidentified",
    "Enter",
    "Backspace",
    "Escape",
    "Tab",
    "Shift",
    "Control",
    "Alt",
    "CapsLock",
    "Meta",
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Home",
    "End",
    "PageUp",
    "PageDown",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "F8",
    "F9",
    "F10",
    "F11",
    "F12",
    "NumLock",
    "ScrollLock",
    "Insert",
    "Delete",
    "ContextMenu",
    "AudioVolumeMute",
    "AudioVolumeDown",
    "AudioVolumeUp",
    "MediaTrackNext",
    "MediaTrackPrevious",
    "MediaStop",
    "MediaPlayPause",
    "LaunchMail",
    "LaunchMediaPlayer",
    "LaunchApp2",
    "BrowserSearch",
    "BrowserHome",
    "BrowserBack",
    "BrowserForward",
    "BrowserStop",
    "BrowserRefresh",
    "BrowserFavorites",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "<",
    ">",
    "\\",
    "|",
    "_",
    "^",
    "±",
    "§",
    "`",
    "~",
    "´",
    "¨",
    "°",
    ...Array.from({ length: 10 }, (_, i) => i.toString()), // All numbers
    ...Array.from({ length: 33 }, (_, i) => String.fromCharCode(i + 33)), // Dots, commas, semicolons, colons, apostrophes, quotes, brackets, braces, slashes, backslashes, hyphens, underscores, plus signs, equal signs, asterisks, ampersands, exclamation marks, question marks, and spaces
  ],
};

export const publicConfig = {
  REACT_APP_NOT_SECRET_FIREBASE_API_KEY: "AIzaSyBi7vrcbYDBMqItWyHMLfaAMyFulJcwUMU",
  REACT_APP_NOT_SECRET_FIREBASE_AUTH_DOMAIN: "swift-habit-app.firebaseapp.com",
  REACT_APP_NOT_SECRET_FIREBASE_PROJECT_ID: "swift-habit-app",
  REACT_APP_NOT_SECRET_FIREBASE_STORAGE_BUCKET: "swift-habit-app.appspot.com",
  REACT_APP_NOT_SECRET_FIREBASE_MESSAGING_SENDER_ID: "951801076098",
  REACT_APP_NOT_SECRET_FIREBASE_APP_ID: "1:951801076098:web:1005b167cf7f27f367179b",
  REACT_APP_NOT_SECRET_FIREBASE_MEASUREMENT_ID: "G-ZCZP3D15Z5",

  REACT_APP_NOT_SECRET_API_URL: "https://api.swifthabit.com",
  REACT_APP_NOT_SECRET_API_LOCAL: "http://localhost:8080",
  REACT_APP_NOT_SECRET_FRONTEND_URL: "https://app.swifthabit.com",
  REACT_APP_NOT_SECRET_FRONTEND_LOCAL: "http://localhost:3000",
};

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? publicConfig.REACT_APP_NOT_SECRET_API_LOCAL
    : publicConfig.REACT_APP_NOT_SECRET_API_URL;

declare global {
  interface Window {
    LOQ: any;
  }
}

// This is the same as the one in the backend
export const pollyVoices = {
  // Neural voices:
  en: "Joanna",
  de: "Vicki",
  es: "Lucia",
  it: "Bianca",
  fr: "Léa",
  nl: "Laura",
  // Standard voices:
  ru: "Tatyana",
};
